.Footer {
    margin: 3em;
    text-align: Center;
}

.Footer .anticon {
    font-size: 3em;

}

.Footer .Credits .TopCoderCredit {
    color: indigo;

}