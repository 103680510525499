.InputTextAreaWithLimit .TextLimit {
    text-align: right;
    background-color: white;
    font-size: 18px;
    padding: 10px 1px;
}

.InputTextAreaWithLimit textarea {
    border: 0;
    width: 100%;
    min-height: 500px;
    font-size: xx-large;
}

.InputTextAreaWithLimit textarea:hover,
.InputTextAreaWithLimit textarea:focus,
.InputTextAreaWithLimit textarea:active {
    border: 0px none transparent !important;
    outline: 0px none transparent !important;
}