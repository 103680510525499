.TranslationLayout {
    margin: 2em;
    background-color: white !important;
    max-width: 1366px;
    margin: 0 auto;
}

.TranslationLayout .SelectPaneContainer {
    padding-bottom: 20px;
    margin-bottom: 0px;
}
