.TranslatedText {
    background-color: white;
    height: 100%;
    font-size: xx-large;
}


.TranslatedText .Status.StatusText {
    font-weight: bolder;
}

.TranslatedText .Paragraph {
    padding: 15px 11px;
}
