.LanguageSelectPane {
    width: 100%;
}

.LanguageSelectPane .ant-select {
    width: 100%;

}


.LanguageSelectPane .RightCircleIcon {
    font-size: 20px;
    text-align: center;
}


@media only screen and (min-width: 600px)  {
    .LanguageSelectPane .RightCircleIcon {
        font-size: 30px;
    }
}
